import React from 'react';
import { Supporter } from './Supporter';

const formAction = 'https://www.paypal.com/cgi-bin/webscr';
const business = 'treasurer@dxs1914.org';

export interface DonationFormProps {
  supporter?: Partial<Supporter>;
}

export const DonationForm = React.forwardRef<
  HTMLFormElement,
  DonationFormProps
>((props: DonationFormProps, formRef) => {
  const { supporter } = props;

  const renderSupportItemNumber = () => {
    const { FirstName, LastName, DisplayName} = supporter ?? {};
    if (!FirstName?.trim() || !LastName?.trim()) {
      return DisplayName?.trim();
    }

    return `${FirstName} ${LastName}`;
  };

  return (
    <form ref={formRef} action={formAction} method="post">
      {/*<!-- Identify your business so that you can collect the payments.*/}
      <input type="hidden" name="business" value={business} />

      {/*<!-- Specify a Donate button. -->*/}
      <input type="hidden" name="cmd" value="_donations" />

      {/*<!-- Specify details about the contribution -->*/}
      <input type="hidden" name="item_name" value="Scholarship Fund Donation" />
      <input
        type="hidden"
        name="item_number"
        value={renderSupportItemNumber()}
      />
      <input type="hidden" name="currency_code" value="USD" />
      <input type="hidden" name="cancel_return" value={window.location.href} />
    </form>
  );
});
