import 'firebase/functions';
import React from 'react';
import '../../FbApp';
import { useSupporter } from '../../useSupporter';
import { Spinner } from '../Spinner';
import { DonateButton } from './DonateButton';
import { DonationRequest } from './DonationRequest';
import { FoundationMessage } from './FoundationMessage';
import './Fund.css';
import { ScholarImageGrid, ScholarTextGrid } from './Scholars';
import { scholars } from './Scholars/Scholar';
import { ScholarshipApplication } from './ScholarshipApplication';
import { SupportHero } from './SupportHero';

const Fund = () => {
  const { supporter, loading } = useSupporter();

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="article donate">
      <SupportHero supporter={supporter}/>
      <DonateButton supporter={supporter}>Donate Now</DonateButton>
      <DonationRequest />
      <ScholarshipApplication />
      <DonateButton supporter={supporter}>Donate Now</DonateButton>
      <div className="flexContainer foundation">
        <FoundationMessage />
      </div>
      <div style={{backgroundColor: '#eeeeee', border: '1px solid #ccc', borderRadius: '4px'}}>
        <ScholarImageGrid scholars={scholars} />
        <ScholarTextGrid scholars={scholars} />
      </div>
    </div>
  );
};

export default Fund;
