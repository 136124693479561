import React from 'react';
import './error.css';

const Error = () => {
    return (
        <div className="error-page article">
            <div className="error-head">&nbsp;Error&nbsp;</div>
            <div className="message">Sorry&hellip;</div>
            <div className="error-foot">Page Not Found</div>
        </div>
    )
}

export default Error;