import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Login } from '../Fund/Login';
import { AuthContext } from './Auth';

export interface PrivateRouteProps extends RouteProps {
  component: any;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const { authorized } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (authorized) {
          return <Component {...routeProps} />;
        } else {
          return <Login />;
        }
      }}
    />
  );
};
