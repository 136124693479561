import firebase from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FbApp = firebase.initializeApp({
    apiKey: "AIzaSyCHi-YtiVW7LpYoBmMWJNrj14ctFJ8qAEg",
    authDomain: "dxs1914-org.firebaseapp.com",
    databaseURL: "https://dxs1914-org.firebaseio.com",
    projectId: "dxs1914-org",
    storageBucket: "dxs1914-org.appspot.com",
    messagingSenderId: "509621610322",
    appId: "1:509621610322:web:e31c681c26250a8dc37c8b",
    measurementId: "G-YSGX9PZ6GE"
});