import * as React from 'react';
import { useState } from 'react';
import Popover from 'react-popover';
import { Scholar, getScholarImage } from '../Scholar';

export type ScholarViewProps = {
    scholar: Scholar
}

export const ScholarView: React.FC<ScholarViewProps> = ({ scholar }) => {
    const [showPopup, setShowPopup] = useState(false);

    if (!scholar.image || !scholar.description) {
        return <></>;
    }

    const tipBody = (
        <div className="tip">
            <div className="tipTitle">{scholar.name}</div>
            <div className="tipContent">{scholar.description}</div>
        </div>
    );

    return (
        <Popover body={tipBody} key={scholar.name} isOpen={showPopup} onOuterAction={() => { setShowPopup(false) }} >
            <div className="scholar" onClick={() => { setShowPopup(!showPopup) }}>
                <div className="imageContainer">
                    <img src={getScholarImage(scholar.image)} alt={scholar.name} />
                </div>
                <div className="scholarName">
                    {scholar.name}
                </div>
            </div>
        </Popover>
    );
}