import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import React, { useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth/Auth';
import { Spinner } from '../Spinner';
import './Login.css';
import { SupportHero } from './SupportHero';

function verifyEmail(user: firebase.User) {
    return user?.sendEmailVerification().then(
      (val) => console.log(val),
      (err) => console.error(err)
    );  
}

async function logout() {
  const auth = firebase.auth();
  if (auth.currentUser) {
    await auth.signOut();
  }
};

export function Login() {
  const { user, authorizing } = useContext(AuthContext);
  const ref = React.createRef<HTMLDivElement>();

  const showAuthUi = (e: HTMLElement | null) => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    if (!e) {
      throw new Error('expected an element');
    }
    ui.start(e, {
      signInOptions: [
        // List of OAuth providers supported.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      /*signInSuccessUrl: rest.location?.pathname,*/
      signInFlow: 'popup',
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          console.log(authResult);
          return false;
        }
      }
    });
  };

  useEffect(() => {
    if (!user) {
      showAuthUi(ref.current);
    }
  }, [user, ref]);

  const renderView = useCallback(() => {
    if (!user) {
      return (
        <div>
          <h1>Please log in</h1>
          <strong>
            You must use the email account that you use to access our chapter
            email distribution list (Google Group).
          </strong>
          <div ref={ref}></div>
        </div>
      );
    }

    if (authorizing) {
      return <Spinner />;
    }

    if (!user.emailVerified) {
      return (
        <div className="article">
          <h1>Verify Your Email Address</h1>
          <div className="verify">
            Before you can proceed, you must verify your email address by
            clicking the button below.
            <button className="verify-button" onClick={() => verifyEmail(user)}>
              Click to send email verification
            </button>
            <ul className="verify-list">
              <li>
                You will receive an email at <strong>{user.email}</strong>.
                <br />
                If you do not see an email your inbox within 5 minutes, please
                make sure that it was not sent to your spam folder.
              </li>
              <li>
                Follow the instructions in the email to complete verification
              </li>
              <li>Refresh this page to proceed</li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h1>Hello, {user.displayName}</h1>
        <button onClick={logout}>Logout</button>
      </div>
    );
  }, [authorizing, user, ref]);

  return (
    <>
      <SupportHero />

      <div className="login">{renderView()}</div>
    </>
  );
}
