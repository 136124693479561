import * as React from 'react';

import './ScholarshipApplication.css';

export const ScholarshipApplication = () => {
  return (
    <section className="noteScholarship">
      <h2>Our Scholarship</h2>
      <div className="scholarshipDetails">
        <p>
          Candidates should exhibit scholarly traits and be actively engaged in
          community service and beyond.
        </p>
        <div className="criteria">
          Eligibility Criteria:
          <ul>
            <li>High School Male Senior Graduating in 2024</li>
            <li>Milwaukee County Resident</li>
            <li>Minimum 2.5 Cumulative GPA</li>
            <li>
              Accepted into a 2 or 4-year accredited college or university
              program
            </li>
          </ul>
        </div>
      </div>
      <p>
        <span>
          All applications must be received via US mail by Saturday, April 6,
          2024
        </span>
        <a
          href="https://drive.google.com/file/d/1npOrGmYB--hm_hCMsH-GUggpHT35rqBi/view"
          target="_blank"
          rel="noopener noreferrer">
          <span className="appButton">
            <i className="fa fa-file-pdf-o"></i>
            &nbsp;&nbsp;Download the Application
          </span>
        </a>
      </p>
    </section>
  );
};
