import QRCode from 'qrcode.react';
import React from 'react';
import { DonationRequest } from '../DonationRequest';
import { FoundationMessage } from '../FoundationMessage';

import './CampaignSheet.css';

const columns = ['Donor', 'Check/Cash', 'Amount'];

export interface CampaignSheetProps {
  url: string;
}

export const CampaignSheet = React.forwardRef<
  HTMLDivElement,
  CampaignSheetProps
>((props, ref) => {
  const { url } = props;
  return (
    <div className="campaign-sheet" ref={ref}>
      <div className="donation-request">
        <div className="header">
          <div>
            <img
              src="/images/pbs-color-seal-200.png"
              alt="The seal of Phi Beta Sigma Fraternity, Inc."
            />
          </div>
          <h1>
            Please support the{' '}
            <span style={{ whiteSpace: 'nowrap' }}>Delta Xi Sigma Chapter</span>{' '}
            <span style={{ whiteSpace: 'nowrap' }}>Sponsor a Scholar</span>{' '}
            Campaign
          </h1>
          <div>
            <img
              src="/images/dxs-logo.png"
              alt="The seal of Delta Xi Sigma Chapter"
            />
          </div>
        </div>
        <DonationRequest />
        <div className="info-section">
          <div>
            <FoundationMessage />
          </div>
          <div className="donate-section">
            <h2>Donate Electronically</h2>
            Would you prefer to donate electronically? Scan the QR Code below to
            access our donation page <br />
            <div className="qr-code">
              <QRCode value={url} renderAs={'svg'} fgColor="#214788" />
            </div>
          </div>
        </div>
        <hr />
        <div style={{color: "#214788"}}>
          <strong>Phi Beta Sigma Fraternity Delta Xi Sigma Education Fund, Inc.</strong>
          <br />
          PO Box 510165 | Milwaukee, WI 53203
          <br />
        </div>
      </div>
      <div className="sheets">
        <table className="sheet page-break">
          <colgroup>
            <col span={1} style={{ width: '*' }} />
            <col span={1} style={{ width: '0' }} />
            <col span={1} style={{ width: '100px' }} />
          </colgroup>

          <thead>
            <tr className="header-row">
              {columns.map((columnName) => {
                return <th key={columnName}>{columnName}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {new Array(16).fill(0).map((_, rowKey) => {
              return (
                <tr key={rowKey}>
                  <td>
                    Name:
                    <br />
                    Phone:
                    <br />
                    Email:
                    <br />
                    Address:
                    <br />
                    <br />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
