import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Supporter } from './components/Fund/Supporter';
import firebase from 'firebase';

const getSupporter = firebase.functions().httpsCallable('getSupporter');

export function useSupporter() {
  const query = new URLSearchParams(useLocation().search);
  const sid = query.get('s')?.trim() ?? '';
  const [loading, setLoading] = useState(false);
  const [supporter, setSupporter] = useState<Partial<Supporter>>({
    DisplayName: 'The Delta Xi Sigma Chapter'
  } as any);

  useEffect(() => {
    if (sid?.trim()) {
      setLoading(true);
      
      getSupporter({ Id: sid })
        .then(({ data }) => {
          setSupporter(data);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [sid]);

  return {
    loading,
    supporter
  };
}
