import React, { PropsWithChildren } from 'react';

export type CtaProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export function Cta(props: PropsWithChildren<CtaProps>) {
  const { children, ...rest } = props;
  return <a className='cta' {...rest}>{children}</a>;
}
