import firebase from 'firebase/app';
import React, { createRef, useCallback } from 'react';
import { DonateButton } from '../DonateButton';
import { Supporter } from '../Supporter';
import { ShareUrl } from './ShareUrl';
import { Social } from './Social';

import './Campaign.css';
import { CampaignSheet } from './CampaignSheet';

export interface CampaignProps {
  user: firebase.User | undefined;
  supporter: Partial<Supporter>;
}

export const Campaign = (props: CampaignProps) => {
  const { user, supporter } = props;
  const sheetRef = createRef<HTMLDivElement>();

  const absoluteUrl = (path: string) => {
    return `${window.location.origin}${path}`;
  };

  const sponsorUrl = useCallback(
    () => (user && absoluteUrl(`/?s=${user.uid}`)) || '',
    [user]
  );
  const sponsorUrlEncoded = useCallback(
    () => encodeURIComponent(sponsorUrl()),
    [sponsorUrl]
  );

  const onPrintForm = () => {
    const content = sheetRef.current?.outerHTML ?? '';
    const head = document.getElementsByTagName('head').item(0)?.outerHTML;
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    if (!mywindow) {
      console.error('Could not open a new window');
      return;
    }

    mywindow.document.write(`<html>${head}<body>${content}</body></html>`);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.addEventListener('load', () => {
      mywindow!.print();
      mywindow!.close();
    });

    return true;
  };

  const renderActions = () => {
    return (
      <div className="actions">
        <div onClick={onPrintForm} style={{ height: '100%' }}>
          <div className="button" style={{ height: '100%' }}>
            Print Campaign Form
          </div>
        </div>
        <a href={sponsorUrl()} style={{ height: '100%' }}>
          <div className="button" style={{ height: '100%' }}>
            Go to your Campaign Page
          </div>
        </a>
        <DonateButton supporter={supporter}>Donate Now</DonateButton>
      </div>
    );
  };

  return (
    <div className="campaign">
      <div className="article">
        <h2>You've taken the first step</h2>
        <span style={{ fontSize: '1.2em', fontWeight: 500 }}>
          You have taken the first step to help us reach our goal, Frat. The
          next step is to promote the campaign. Each brother is asked to raise 
          at least $250. Your personal contributions are very much appreciated, 
          but we are asking that you also obtain sponsorship from at least 3 
          other donors.
        </span>
      </div>
      <h2>Take Action</h2>
      <div style={{ marginBottom: '16px' }}>{renderActions()}</div>
      <div className="promote">
        <div className="promote-child">
          <ShareUrl url={sponsorUrl()} />
        </div>
        <div className="promote-child">
          <Social urlEncoded={sponsorUrlEncoded()} />
        </div>
      </div>
      <CampaignSheet ref={sheetRef} url={sponsorUrl()} />
    </div>
  );
};
