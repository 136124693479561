import React from 'react';
import './SupportHero.css';
import { Supporter } from './Supporter';

export interface SupportHeroProps {
  supporter?: Partial<Supporter>;
  children?: JSX.Element;
}
export function SupportHero(props: SupportHeroProps) {
  const { children, supporter } = props;

  return (
    <div className="support-hero">
      <div className="overlay"></div>
      <div className="content article">
        <div className="left">
          <span className="support-text">
            {children ?? (
              <>
                <div className='medium'>
                  Phi Beta Sigma Fraternity Delta Xi Sigma Education Fund, Inc.
                </div>
                <div>Sponsor a Scholar Campaign</div>
                {!!supporter?.DisplayName?.trim() && (
                  <div style={{marginTop: '1em'}}>
                    <div className='medium'>On behalf of</div>
                    <div>{supporter.DisplayName}</div>
                    <div className='medium'>we thank you for your support!</div>
                  </div>
                )}
              </>
            )}
          </span>
        </div>
        <div className="right">
          <img src="/images/grad-drop-shadow.png" alt="A graduate" />
        </div>
      </div>
    </div>
  );
}
