import QRCode from 'qrcode.react';
import React from 'react';

export interface ShareUrlProps {
  url: string;
}

export function ShareUrl(props: ShareUrlProps) {
  const { url } = props;
  const urlCopyRef = React.createRef<HTMLInputElement>();

  const copyUrl = () => {
    const textBox = urlCopyRef.current;
    if (textBox) {
      //textBox.select();
      navigator.clipboard.writeText(textBox.value);
    }
  };

  return (
    <div>
      <h2>Help promote the campaign by sharing your personalized URL</h2>
      <h3 className="instruct">
        Copy the link below and share it manually e.g. via email, sms, etc.
      </h3>
      <div className="copyUrl">
        <input ref={urlCopyRef} type="text" value={url} readOnly={true} />
        <button onClick={copyUrl}>
          <i className="fa fa-clipboard" aria-hidden="true"></i>
        </button>
      </div>
      <h3 className="instruct">
        Scan the QR Code with your mobile device or copy and paste it to share with others
      </h3>
      <div className="qrcode-containter">
        <QRCode value={url} renderAs={'canvas'} fgColor="#214788" />
      </div>
    </div>
  );
}
