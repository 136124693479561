import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { AuthContext, AuthProvider } from './components/Auth/Auth';
import { Login } from './components/Fund/Login';
import { PrivateRoute } from './components/Auth/PrivateRoute';
import ContactUs from './components/ContactUs/ContactUs';
import Error from './components/Error/error';
import Fund from './components/Fund/Fund';
import { Support } from './components/Fund/Support';

import './App.css';

const keyPress = (e: React.KeyboardEvent<HTMLElement>) => {
  e.preventDefault();
  if (e.key === ' ' || e.key === 'Enter') {
    e.currentTarget.click();
  }
};

const LogoutButton = () => {
  const { user } = useContext(AuthContext);
  const auth = firebase.auth();

  const signOut = async () => {
    await auth.signOut();
    window.location.href = '/support';
  };

  if (user) {
    return (
      <div
        className="logout"
        role="button"
        tabIndex={0}
        onClick={signOut}
        onKeyPress={keyPress}>
        Logout
      </div>
    );
  }

  return <></>;
};

function SupportButton() {
  const { user, authorized } = useContext(AuthContext);

  if(!user?.emailVerified) {
    return null;
  }

  if (!authorized) {
    return <span>Profile</span>;
  }

  return (
    <NavLink to="/support" exact>
      Profile
    </NavLink>
  );
}

function App(): JSX.Element {
  return (
    <AuthProvider>
      <header>
        <div className="header-inner">
          <div className="image-container">
            <img
              className="header-image"
              src="/images/SigmaSealWhite.png"
              alt="The shield of Phi Beta Sigma Fraternity, Inc."
            />
          </div>
          <nav className="topnav" id="myTopnav">
            <NavLink to="/" exact>
              Home
            </NavLink>
            <NavLink to="/contact" exact>
              Contact Us
            </NavLink>
            <SupportButton />
            <LogoutButton />
          </nav>
        </div>
      </header>
      <div className="container">
        <div className="App">
          <Switch>
            <PrivateRoute path="/support" component={Support} exact />
            <Route path="/contact" component={ContactUs} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/" component={Fund} exact/>
            <Route component={Error} status={404} />
          </Switch>
        </div>
        <footer>
          <hr className="article separator" />
          <div className="social-links article">
            <div className="social-links-inner">
              <a
                href="https://www.facebook.com/deltaxisigma"
                target="_blank"
                rel="noopener noreferrer">
                <i className="icon icon-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/wisconsinsigmas_dxs/"
                target="_blank"
                rel="noopener noreferrer">
                <i className="icon icon-instagram"></i>
              </a>
            </div>
          </div>
          <div className="footer-container article">
            <div className="chapter">
              <span className="bold">Delta Xi Sigma Chapter | </span>
              <span style={{ whiteSpace: 'nowrap' }}>
                Phi Beta Sigma Fraternity, Inc.
              </span>
            </div>
            <div className="address">
              <span
                itemProp="address"
                itemScope
                itemType="http://schema.org/PostalAddress">
                <span className="nowrap" itemProp="streetAddress">
                  PO Box 510165
                </span>
                &thinsp;|&thinsp;
                <span itemProp="addressLocality">Milwaukee</span>,&thinsp;
                <span itemProp="addressRegion">WI</span>&thinsp;
                <span itemProp="postalCode">53203</span>
              </span>
            </div>
          </div>
        </footer>
      </div>
    </AuthProvider>
  );
}

export default App;
