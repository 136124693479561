import React, { FC } from 'react';
import { DonationForm } from './DonationForm';
import { Supporter } from './Supporter';

import './DonateButton.css';

export interface DonateButtonProps {
  supporter?: Partial<Supporter>;
}

export const DonateButton: FC<DonateButtonProps> = ({ supporter, children }) => {
  const formRef = React.createRef<HTMLFormElement>();

  const onClick = () => {
    formRef.current?.submit();
  };

  return (
    <div className="donate-container">
      <DonationForm ref={formRef} supporter={supporter} />
      <button onClick={onClick} aria-label="Donate">
        {children}
      </button>
    </div>
  );
};
