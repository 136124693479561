import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FbApp as app } from '../../FbApp';

const verifyUserApi = firebase.functions().httpsCallable('verifyUser');

async function verifyUser(user: firebase.User): Promise<boolean> {
  if(!user) {
    console.error(new Error("expected a valid user"));
    return false;
  }
  const result = await verifyUserApi({
    Id: user?.uid
  });

  return result.data;
}

export interface LoginContext {
  user?: firebase.User;
  authorized: boolean;
  authorizing: boolean;
}

const defaultContext: LoginContext = {
  authorized: false,
  authorizing: false
};

export const AuthContext = React.createContext<LoginContext>(defaultContext);

export const AuthProvider: React.FC<PropsWithChildren<any>> = ({
  children
}) => {
  const [user, setUser] = useState<firebase.User>();
  const [authorized, setAuthorized] = useState(false);
  const [authorizing, setAuthorizing] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged(
      (u) => {
        setUser(u as firebase.User);
        setLoading(false);
      },
      (e) => console.log(e)
    );
  }, []);

  useEffect(() => {
    if (user?.email) {
      setAuthorizing(true);
      verifyUser(user)
        .then(setAuthorized)
        .finally(() => setAuthorizing(false));
    }
  }, [user]);

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, authorized, authorizing }}>
      {children}
    </AuthContext.Provider>
  );
};
