import "firebase/functions";
import React, { FormEventHandler, useState } from "react";
import "./contactUs.css";
import { FbApp as app } from "../../FbApp";

const contactUs = app.functions().httpsCallable("contactUs");

const ContactUs = () => {
  const [completed, setCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>();

  const renderErrors = () => {
    if (!errors) {
      return null;
    }

    return (
      <div className="errors">
        <h3>Sorry, the form was not submitted</h3>
        {Object.entries(errors).map((e) => (
          <span key={e[0]}>
            <strong>{e[0]}:&ensp;</strong>
            {e[1]}
          </span>
        ))}
      </div>
    );
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const data = Array.from(new FormData(form).entries()).reduce<
      Record<string, string>
    >((a, c) => {
      a[c[0]] = c[1].toString();
      return a;
    }, {});

    setSubmitting(true);
    contactUs(data)
      .then(() => setCompleted(true))
      .catch((e) => {
        const details: Record<string, string> = e.details ?? {};
        setErrors(details);
        console.error(e);
      })
      .finally(() => setSubmitting(false));
  };

  if (completed) {
    return (
      <div className="article">
        <h1>Form Submitted</h1>
        <div className="article-container">
          <h2>Thank you for reaching out to us!</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="article">
      <h1>Contact Us</h1>
      <div className="article-container">
        <form
          action=""
          method=""
          id="ss-form"
          target="_self"
          onSubmit={onSubmit}
        >
          <fieldset className="fieldset" disabled={submitting}>
            {!!errors && renderErrors()}
            <ol className="ss-question-list padding-left-0">
              <div className="ss-form-question errorbox-good" role="listitem">
                <div dir="ltr" className="ss-item ss-item-required ss-text">
                  <div className="ss-form-entry">
                    <label className="ss-q-item-label" htmlFor="entry_14925608">
                      <div className="ss-q-title">
                        Name
                        <label
                          htmlFor="itemView.getDomIdToLabel()"
                          aria-label="(Required field)"
                        ></label>
                        <span className="ss-required-asterisk">*</span>
                      </div>
                      <div className="ss-q-help ss-secondary-text" dir="ltr">
                        Enter your name
                      </div>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="ss-q-short"
                      id="entry_14925608"
                      dir="auto"
                      aria-label="Name Enter your name "
                      aria-required={true}
                      required={true}
                      title=""
                    />
                    <div className="error-message"></div>
                    <div className="required-message">
                      This is a required question
                    </div>
                  </div>
                </div>
              </div>
              <div className="ss-form-question errorbox-good" role="listitem">
                <div dir="ltr" className="ss-item ss-item-required ss-text">
                  <div className="ss-form-entry">
                    <label
                      className="ss-q-item-label"
                      htmlFor="entry_1078435069"
                    >
                      <div className="ss-q-title">
                        Email
                        <label
                          htmlFor="itemView.getDomIdToLabel()"
                          aria-label="(Required field)"
                        ></label>
                        <span className="ss-required-asterisk">*</span>
                      </div>
                      <div className="ss-q-help ss-secondary-text" dir="ltr">
                        Enter your email address
                      </div>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="ss-q-short"
                      id="entry_1078435069"
                      dir="auto"
                      aria-label="Email Enter your email address Must be a valid email address"
                      aria-required={true}
                      required={true}
                      title="Must be a valid email address"
                    />
                    <div className="error-message">
                      Must be a valid email address
                    </div>
                    <div className="required-message">
                      This is a required question
                    </div>
                  </div>
                </div>
              </div>
              <div className="ss-form-question errorbox-good" role="listitem">
                <div dir="ltr" className="ss-item  ss-text">
                  <div className="ss-form-entry">
                    <label
                      className="ss-q-item-label"
                      htmlFor="entry_1734388761"
                    >
                      <div className="ss-q-title">Phone number</div>
                      <div className="ss-q-help ss-secondary-text" dir="ltr">
                        Enter a phone number including area code
                      </div>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      className="ss-q-short"
                      id="entry_1734388761"
                      dir="auto"
                      aria-label="Phone number Enter a phone number including area code "
                      title=""
                    />
                    <div className="error-message"></div>
                    <div className="required-message">
                      This is a required question
                    </div>
                  </div>
                </div>
              </div>
              <div className="ss-form-question errorbox-good" role="listitem">
                <div
                  dir="ltr"
                  className="ss-item ss-item-required ss-paragraph-text"
                >
                  <div className="ss-form-entry">
                    <label
                      className="ss-q-item-label"
                      htmlFor="entry_1973703491"
                    >
                      <div className="ss-q-title">
                        How may we be of service?
                        <label
                          htmlFor="itemView.getDomIdToLabel()"
                          aria-label="(Required field)"
                        ></label>
                        <span className="ss-required-asterisk">*</span>
                      </div>
                      <div className="ss-q-help ss-secondary-text" dir="ltr">
                        Please describe your reason for contacting us
                      </div>
                    </label>
                    <textarea
                      name="details"
                      rows={8}
                      cols={0}
                      className="ss-q-long"
                      id="entry_1973703491"
                      dir="auto"
                      aria-label="How may we be of service? Please describe your reason for contacting us "
                      aria-required={true}
                      required={true}
                    ></textarea>
                    <div className="error-message"></div>
                    <div className="required-message">
                      This is a required question
                    </div>
                  </div>
                </div>
              </div>
              <div className="ss-item ss-navigate">
                <table id="navigation-table">
                  <tbody>
                    <tr>
                      <td
                        className="ss-form-entry goog-inline-block"
                        id="navigation-buttons"
                        dir="ltr"
                      >
                        <input
                          type="submit"
                          name="submit"
                          value="Submit"
                          id="ss-submit"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ol>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
