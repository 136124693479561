import firebase from 'firebase/app';
import 'firebase/functions';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../Auth/Auth';
import { Campaign } from './Campaign';
import { CreateSupporterForm } from './CreateSupporterForm';
import { Supporter } from './Supporter';
import { SupportHero } from './SupportHero';

import { Spinner } from '../Spinner';
import './Fund.css';
import './Support.css';

const getSupporter = firebase.functions().httpsCallable('getSupporter');

export const Support: React.FC<any> = () => {
  const { user, authorized, authorizing } = useContext(AuthContext);
  const [supporter, setSupporter] = useState<Supporter>();
  const [loading, setLoading] = useState<boolean>(false);
  const shortName = useMemo(() => {
    const { DisplayName, FirstName } = supporter ?? {};
    return FirstName ?? DisplayName;
  }, [supporter]);

  useEffect(() => {
    document.title = 'DSX Fund: Sponsor a Scholar';
    if (user?.emailVerified && authorized) {
      setLoading(true);
      getSupporter({
        Id: user.uid
      })
        .then((s) => setSupporter(s.data))
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [user, authorized]);

  if (authorizing || loading) {
    return <Spinner />;
  }

  if (!authorized) {
    return (
      <div className="article">
        <h1>Not Authorized</h1>
        <p>Sorry, Frat. You cannot use {user?.email} to sign into this page.</p>
        <p>
          Please logout, and then login using the email account with which you
          are subscribed to our chapter email distribution list (Google Group).
        </p>
      </div>
    );
  }

  const renderBody = () => {
    return (
      (supporter && <Campaign {...{ user, supporter }} />) || (
        <CreateSupporterForm onSupporterCreated={setSupporter} />
      )
    );
  };

  return (
    <div>
      <SupportHero>
        <>
          {shortName ? `Bro. ${shortName}` : 'Brother'}, thank you for
          supporting the Sponsor a Scholar campaign!
        </>
      </SupportHero>
      <div className="article support">{renderBody()}</div>
    </div>
  );
};
