import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../Auth/Auth';
import { Spinner } from '../Spinner';
import { Supporter } from './Supporter';

const getSupporter = firebase.functions().httpsCallable('getSupporter');
const createSupporter = firebase.functions().httpsCallable('createSupporter');

export interface CreateSupporterFormProps {
  onSupporterCreated: (s: Supporter) => void;
}

export const CreateSupporterForm = (props: CreateSupporterFormProps) => {
  const { onSupporterCreated } = props;
  const { user } = useContext(AuthContext);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [joining, setJoining] = useState(false);

  const onJoinCampaign = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setJoining(true);
    const request = {
      Id: user?.uid,
      FirstName: firstName,
      LastName: lastName
    };
    createSupporter(request)
      .then(() => getSupporter(request))
      .then((s) => onSupporterCreated(s.data))
      .catch((e) => console.log(e))
      .finally(() => setJoining(false));
  };

  if (joining) {
    return (
        <Spinner />
    );
  }

  return (
    <div className="joinCampaign">
      <p>
        To get started, Please enter the information below. This name will be
        visible to donors upon completing their donation, so please use your
        actual name.
      </p>
      <form onSubmit={onJoinCampaign}>
        <div>
          <label htmlFor="firstName">First name:</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            required
            onChange={(e) => setFirstName(e.currentTarget.value)}
            placeholder="Enter your First Name"></input>
        </div>
        <div>
          <label htmlFor="lastName">Last name:</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            required
            onChange={(e) => setLastName(e.currentTarget.value)}
            placeholder="Enter your Last Name"></input>
        </div>
        <div>
          <button type="submit">Join the Campaign</button>
        </div>
      </form>
    </div>
  );
};
