export interface Scholar {
  name: string;
  image?: string;
  description?: string;
  year: number;
}

export function getScholarImage(name: string): string {
  return `${process.env.PUBLIC_URL}/images/scholars/${name}`;
}

export const scholars: Scholar[] = [
  {
    name: 'Alexandria Brown',
    description:
      '2016 recipient Alexandria Brown is a Hampton University graduate with a BA in Sociology.  She will continue her education this fall at the University of Wisconsin Law school.',
    year: 2016
  },
  {
    name: 'Dante Nobles',
    image: 'DanteNobles.png',
    description:
      '2016 recipient Dante Nobles is a senior in his last semester at Grand Canyon University. His plan is to attend graduate school at Arizona State University to achieve his masters in exercise science after graduate.',
    year: 2016
  },
  {
    name: 'Malik Johnson',
    year: 2017
  },
  {
    name: 'Siarah Jones',
    year: 2017
  },
  {
    name: 'Braylen Stevens',
    image: 'BraylenStevens.png',
    description:
      '2017 recipient Braylen Stevens is a graduating senior at DePaul University (Chicago, IL) pursuing his degree in Accounting with minors in Digital Marketing & Communication Media. He plans to enter the world of finance in tech and is in the process of obtaining his CPA.',
    year: 2017
  },
  {
    name: 'Destiny Scott Dyson',
    year: 2018
  },
  {
    name: 'Dawt Iang',
    year: 2018
  },
  {
    name: 'Jarell Powell',
    year: 2018
  },
  {
    name: 'Mohamed Farok Rashid',
    year: 2018
  },
  {
    name: 'Donald Singleton',
    year: 2019
  },
  {
    name: 'Richard James',
    year: 2019
  },
  {
    name: 'Isaac Wells',
    year: 2019
  },
  {
    name: 'Obinna Anyamele',
    year: 2020
  },
  {
    name: 'Kevin Norris',
    year: 2020
  },
  {
    name: 'Justin Greenlee',
    image: 'JustinGreenlee.png',
    description:
      '2020 recipient Justin Greenlee attends Concordia University of Chicago studying Sports Management with plans of becoming a sports agent.',
    year: 2020
  },
  {
    name: 'Naseem Ahmad',
    image: 'NaseemAhmad.png',
    description:
      '2021 recipient Naseem Ahmad attends Northern Michigan University',
    year: 2021
  },
  {
    name: 'Kristopher English',
    image: 'KristopherEnglish.jpg',
    description:
      '2022 recipient Kristopher English attends Barry University in Orlando, FL. He is pursuing a degree in Mass Communication/Media Studies.',
    year: 2022
  },
  {
    name: 'Cannon Ozongwu',
    image: 'CannonOzongwu.jpg',
    description:
      '2022 recipient Cannon Ozongwu attends Marquette University and aspires to be an Engineer.',
    year: 2022
  },
  {
    name: 'Carmelo Knight',
    image: 'CarmeloKnight.jpg',
    description:
      '2023 recipient Carmelo Knight attends Marquette University',
    year: 2023
  },
  {
    name: 'George Walker',
    image: 'GeorgeWalker.jpg',
    description:
      '2023 recipient George Walker attends the University of Wisconsin-Madison',
    year: 2023
  }
];
