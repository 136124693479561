import React from 'react';

import './Spinner.css';


export function Spinner() {
    return (
        <div className='loading'>
            <h2>Loading...</h2>
            <SpinnerInner />
        </div>
    )
}

export function SpinnerInner() {
  return (
    <div className="lds-circle">
      <div>
        <img src="./images/pbs-color-seal.jpg" alt="Spinning seal of Phi Beta Sigma Fraternity, Inc."/>
      </div>
    </div>
  );
}
