import * as React from 'react';

export const FoundationMessage = () => {
  return (
    <div className="foundationMessage">
      <h2>About Us</h2>
      <p>
        <strong>
          <span className="nobreak">
            Phi Beta Sigma Fraternity, Incorporated{" "}
          </span>
        </strong>
        <span>
          is a Brotherhood of Conscious Men actively serving our communities.
        </span>
      </p>
      <p>
        <strong>
          <span className="nobreak">Phi Beta Sigma Fraternity</span>
          <span> </span>
          <span className="nobreak">Delta Xi Sigma </span>
          <span>Education Fund, Inc. </span>
        </strong>
        <span>
          is a 501(c)(3) non-profit organization committed to providing
          educational and financial assistance to the youth in the greater
          Milwaukee community.
        </span>
      </p>
      <p>
        <strong>Sponsor a Scholar</strong> is our annual Education and
        Scholarship campaign to raise funds in order to provide scholarships and
        financial support to graduating high school seniors as they pursue their
        post-secondary education.
      </p>
    </div>
  );
};
