import * as React from 'react';
import { FC } from 'react';
import { Scholar } from './Scholar';
import './ScholarTextGrid.css';

export interface ScholarTextGrid {
  scholars: Scholar[];
}
export const ScholarTextGrid: FC<ScholarTextGrid> = ({ scholars = [] }) => {
  const years = [...scholars].reduce((prev, curr) => {
    const group = prev.get(curr.year) ?? [];
    group.push(curr);

    prev.set(curr.year, group);
    return prev;
  }, new Map<number, Scholar[]>());

  const elements: JSX.Element[] = [];

  Array.from(years.keys())
    .sort((a, b) => b - a)
    .forEach((key) => {
      elements.push(
        <div className="scholarYear" key={key}>
          <h3>{key}</h3>
          {years.get(key)!.map((s, i, a) => {
            return (
              <div className="scholarTextName" key={i}>
                {s.name}
              </div>
            );
          })}
        </div>
      );
    });

  return (
    <div>
      <h2>List of all past recipients:</h2>
      <div className="allScholars">{elements}</div>
    </div>
  );
};
