import React from 'react';
import { Cta } from '../Cta';

export interface SocialProps {
  urlEncoded: string;
}

export function Social(props: SocialProps) {
  const { urlEncoded } = props;
  return (
    <>
      <h2>Promote the campaign through your social media networks</h2>
      <div className="social">
        <Cta
          href={`https://www.facebook.com/sharer.php?u=${urlEncoded}`}
          target="_blank"
          rel="noopener noreferrer">
          <div className="platform">
            <i className="icon icon-facebook"></i>
            Share on Facebook
          </div>
        </Cta>
        <Cta
          href={`https://twitter.com/intent/tweet?url=${urlEncoded}&text=Sponsor%20a%20Scholar!&hashtags=scholars,dxs1914`}
          target="_blank"
          rel="noopener noreferrer">
          <div className="platform">
            <i className="icon icon-twitter"></i>
            Share on Twitter
          </div>
        </Cta>
        <Cta
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${urlEncoded}&title=Sponsor%20a%20Scholar!&summary=Support%20the%20Delta%20Xi%20Sigma%20Education%20Fund`}
          target="_blank"
          rel="noopener noreferrer">
          <div className="platform">
            <i className="fa fa-linkedin-square"></i>
            Share on LinkedIn
          </div>
        </Cta>
      </div>
    </>
  );
}
