import React from 'react';

export function DonationRequest() {
  return (
    <>
      <p>
        We, the men of <strong>Phi Beta Sigma Fraternity, Inc.</strong>, are a
        Brotherhood of Conscious Men actively serving our communities. We are
        turning to you today on behalf of the{' '}
        <strong>
          Phi Beta Sigma Fraternity Delta Xi Sigma Education Fund, Inc.
        </strong>{' '}
        Sponsor a Scholar Campaign. Our program provides $1,000 college
        scholarships to graduating male high school seniors residing in
        Milwaukee County.
      </p>
      <p>
        Pursuing higher education is expensive. These high costs
        can deter students from pursuing a degree, prevent them from completing
        their degree, or saddle them with long term financial debt.
      </p>
      <p>
        All of the money received from donations goes directly to the recipients of
        our scholarships. Every donation, of any amount, helps our students
        realize their potential. It also helps inspire other students from our
        community that they have the power to pursue their dreams. We are so
        grateful that you have taken the time to consider donating to our cause.
      </p>
    </>
  );
}
