import * as React from 'react';
import { FC } from 'react';
import { Scholar } from '../Scholar';
import { ScholarView } from './ScholarView';

import './ScholarImageGrid.css';

export interface ScholarImageGridProps {
  scholars: Scholar[];
}

export const ScholarImageGrid: FC<ScholarImageGridProps> = ({
  scholars = []
}) => {
  return (
    <div className="scholarImageGrid">
      <h2 className="meetScholars">Meet Some of Our Scholars</h2>
      <div className="scholarImageContainer">
        {[...scholars]
          .filter(({image}) => !!image)
          .sort(({ year: a }, { year: b }) => b - a)
          .slice(0, 5)
          .map((element, i) => {
            return (
              <ScholarView scholar={element} key={`${element.name} ${i}`} />
            );
          })}
      </div>
    </div>
  );
};
